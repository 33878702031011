<template>
  <div class="invite">
    <AccountSlideBar></AccountSlideBar>
    <div class="invite-box">
      <div class="title">
        {{ $t("account_invite_a_friend") }}
      </div>
      <div class="subTitle">
        {{ $t("account_refer_friends") }}
      </div>
      <div class="content-box">
        <div class="content-box-message">
          {{ $t("account_tell_a_friend") }}
        </div>
        <div class="content-box-separator-line"></div>
        <div class="content-info">
          <div class="content-title">{{ $t("account_invitation_code") }}</div>
          <button
            type="button"
            class="
              content-btn
              btn-size-format-1 btn-radius-format-1 btn-color-2 btn-cursor
            "
            @click="copy(userInfo.invitationCode)"
            @mouseover="showCopyTip($event, true)"
            @mouseleave="showCopyTip($event, false)"
            :content="userInfo.invitationCode"
          >
            {{ userInfo.invitationCode }}
          </button>
        </div>
      </div>
      <div class="content-box content-box-top-space">
        <div class="content-box-message">
          {{ $t("account_send_a_link") }}
        </div>
        <div class="content-box-separator-line"></div>
        <div class="content-info">
          <div class="content-title">{{ $t("account_invite_via_link") }}</div>
          <button
            type="button"
            class="
              content-btn
              btn-size-format-1 btn-radius-format-1 btn-color-2 btn-cursor
            "
            @click="copy(userInfo.invitationLink)"
            @mouseover="showCopyTip($event, true)"
            @mouseleave="showCopyTip($event, false)"
            :content="userInfo.invitationLink"
          >
            <span> {{ userInfo.invitationLink }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSlideBar from "../../components/AccountSlideBar.vue";

export default {
  name: "Invite",
  components: { AccountSlideBar },
  data() {
    return {
      userInfo: {
        invitationCode: 10208671,
        invitationLink: this.GLOBAL.getWebDomain() + "/pricing?i=10208671",
      },
    };
  },
  methods: {
    copy(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
    },
    showCopyTip(e, value) {
      if (value) {
        e.target.style.minWidth = e.toElement.offsetWidth + "px";
      }
      e.target.innerHTML = value
        ? this.$t("account_click_to_copy")
        : e.target.getAttribute("content");
    },
    getUserInfo() {
      this.$axios.get("/auth/user-info").then((response) => {
        this.$storage.set("user-info", response, 60 * 10);
        this.assignmentResponse(response);
      });
    },
    assignmentResponse(response) {
      this.userInfo.invitationCode = response.data.number;
      this.userInfo.invitationLink =
        this.GLOBAL.getWebDomain() + "/pricing?i=" + response.data.number;
    },
  },
  created() {
    let userInfo = this.$storage.get("user-info");
    if (userInfo === null) {
      this.getUserInfo();
    } else {
      this.assignmentResponse(userInfo);
    }
  },
};
</script>

<style scoped>
.invite {
  min-height: 100vh;
  padding-left: 230px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: #f8f8f8;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .invite {
    padding-left: 65px;
  }
}

.invite-box {
  max-width: 1100px;
  margin-top: 35px;
  padding: 40px 16px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  line-height: 37px;
  color: #1d1d1d;
}

.subTitle {
  max-width: 526px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
  margin-top: 16px;
}

.content-box {
  min-height: 180px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 5px rgba(39, 55, 61, 0.05);
  border-radius: 4px;
  margin-top: 24px;
}

.content-box-message {
  margin: 40px 20px;
  font-size: 16px;
  line-height: 18px;
  color: #1d1d1d;
}

.content-box-separator-line {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.content-info {
  margin: 20px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content-title {
  margin-right: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #1d1d1d;
}

.content-btn {
  width: auto;
  height: auto;
  max-width: 100%;
  min-height: 38px;
  margin: 20px 0;
  border: 1px solid #e3e3e3;
  padding: 0 50px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

.content-box-top-space {
  margin-top: 16px;
}
</style>
